<template>
  <Form
    :model="addChangeData"
    :rules="addChangeValidator"
    :label-colon="true"
    :label-width="90"
    ref="addChangeModal"
  >
    <FormItem prop="companyUnit" label="单位名称">
      <Input
      :maxlength="20"
        v-model.trim="addChangeData.companyUnit"
        style="width: 380px"
      ></Input>
    </FormItem>
    <FormItem prop="type" label="类型">
      <Select v-model="addChangeData.type" style="width: 380px">
        <Option
          v-for="item in selectTypeList"
          :value="item.dictKey"
          :key="item.dictKey"
          >{{ item.dictValue }}</Option
        >
      </Select>
    </FormItem>
    <FormItem prop="modelName" label="名称">
      <Input
      :maxlength="20"
        v-model.trim="addChangeData.modelName"
        style="width: 380px"
      ></Input>
    </FormItem>
    <FormItem prop="appId" label="APPID">
      <Input :maxlength="20" v-model.trim="addChangeData.appId" style="width: 380px"></Input>
    </FormItem>
    <FormItem prop="secret" label="secret">
      <Input :maxlength="200" v-model.trim="addChangeData.secret" style="width: 380px"></Input>
    </FormItem>
  </Form>
</template>

<script>
export default {
  props: ["titleModal", "changeId", "addChangeData","selectTypeList"],
  data() {
    return {
      addChangeValidator: {
        companyUnit: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        modelName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        appId: [{ required: true, message: "请输入APPID", trigger: "blur" }],
        secret: [{ required: true, message: "请输入secret", trigger: "blur" }],
      },
    };
  },
  methods: {
    //新增/修改保存
    addChangeSave() {
      this.$refs.addChangeModal.validate((status) => {
        if (status) {
          if (this.titleModal == "新增公众号") {
            this.$post("/voteapi/api/pc/aggre/application/saveApplication", {
              ...this.addChangeData,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
              createId: parent.vue.loginInfo.userinfo.id,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "保存成功",
                  });
                  this.$emit('hadlePageSizeFn')
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
              })
              .catch(err);
          } else {
            //修改
            this.$post("/voteapi/api/pc/aggre/application/updateApplication", {
              ...this.addChangeData,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
              createId: parent.vue.loginInfo.userinfo.id,
              id: this.changeId,
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "保存成功",
                  });
                  this.$emit('hadlePageSizeFn')
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
              })
              .catch(err);
          }
        }
      });
    },
  },
};
</script>
    
<style scoped lang='less'>
</style>