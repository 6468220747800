<template>
  <LiefengContent>
    <template v-slot:title>公众号管理</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchForm"
        :label-colon="true"
        :inline="true"
        :label-width="50"
        style="width: 630px"
      >
        <FormItem label="名称" style="margin-bottom: 0">
          <Input
          :maxlength="20"
            v-model.trim="searchForm.modelName"
            style="max-width: 140px"
            placeholder="请输入公众号名称"
          ></Input>
        </FormItem>
        <FormItem label="单位名称" style="margin-bottom: 0" :label-width="80">
          <Input
          :maxlength="20"
            v-model.trim="searchForm.companyUnit"
            style="max-width: 140px"
            placeholder="请输入单位名称"
          ></Input>
        </FormItem>
        <FormItem label="类型" style="margin-bottom: 0">
          <Select v-model="searchForm.type" style="width: 140px">
            <Option
              v-for="item in selectTypeList"
              :value="item.dictKey"
              :key="item.dictKey"
              >{{ item.dictValue }}</Option
            >
          </Select>
        </FormItem>
      </Form>
      <Button type="primary" icon="ios-search-outline" @click="search"
        >查询</Button
      >
      <Button type="success" icon="ios-refresh" @click="reset"
        >重置</Button
      >
      <Button type="warning" @click="addOfficial" icon="md-add">新增</Button>
      <Button type="error" @click="deleteRow(tableSelectStr)" icon="md-trash"
        >批量删除</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :tableData="tableData"
        :talbeColumns="talbeColumns"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :total="total"
        :curPage="curPage"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>
      <!-- 新增/修改弹窗 -->
      <LiefengModal
        :title="titleModal"
        :value="addChangeStatus"
        @input="addChangeFn"
        width="500px"
      >
        <template v-slot:contentarea>
          <AddOChangefficial @hadlePageSizeFn="hadlePageSizeFn" ref="addOfficial" :selectTypeList="selectTypeList" v-if="addChangeStatus" :addChangeData="addChangeData" :changeId="changeId" :titleModal="titleModal"></AddOChangefficial>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="addChangeFn(false)"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="addChangeSave">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/officialmanage')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import { format } from "@/utils/formatTime.js";
import AddOChangefficial from '@/views/official/childrens/addChangeOfficial'
export default {
  data() {
    return {
      searchForm: {
        companyUnit: "",
        type: "",
        modelName: "",
      },
      //批量删除
      tableSelectStr: "",
      selectTypeList: [],
      curPage: 1,
      total: 0,
      pageSize: 20,
      loading: false,
      talbeColumns: [
        {
          type: "selection",
          align: "center",
          width: 60,
        },
        {
          key: "companyUnit",
          title: "单位名称",
          align: "center",
          minWidth: 120,
        },
        {
          key: "type",
          title: "类型",
          align: "center",
          minWidth: 120,
          render: (h,params) => {
            let type = '';
            this.selectTypeList.map(item => {
              if(item.dictKey == params.row.type) {
                type = item.dictValue;
              }
            })
            return h('div',type)
          }
        },
        {
          key: "modelName",
          title: "公众号名称",
          align: "center",
          minWidth: 120,
        },
        {
          key: "orgName",
          title: "服务范围",
          align: "center",
          minWidth: 120,
        },
        {
          key: "createTime",
          title: "添加时间",
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            let time = format(params.row.createTime, "yyyy-MM-dd HH:mm:ss");
            return h("div", time);
          },
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                     width: '65px',
                    marginRight: "10px",
                    textAlign: "center",
                  },
                  on: {
                    click: () => {
                      this.changeOfficial(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "success",
                        size: "small",
                        icon: "ios-arrow-down",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.seeFans(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看粉丝"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.seeTweet(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "查看推文"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.deleteRow(params.row.id);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      //新增/修改弹窗
      titleModal: "",
      changeId: "",
      addChangeStatus: false,
      addChangeData: {},
      menuId: '',
    };
  },
  methods: {
    //查看粉丝
    seeFans(id) {
      this.$Message.error("暂未实现")
    },
    //查看推文
    seeTweet(row) {
      this.$router.push({
        path: '/officialtweet',
        query: {
          id: row.code,
          menuId: this.menuId
        }
      });
    },
    hadlePageSizeFn() {
      this.addChangeStatus = false;
      this.hadlePageSize({
        page: this.curPage,
        pageSize: this.pageSize
      })
    },
    //重置
    reset() {
      this.searchForm.companyUnit = '';
      this.searchForm.type = '';
      this.searchForm.modelName = '';
       this.hadlePageSize({
        page: this.curPage,
        pageSize: this.pageSize
      })
    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      })
    },
    //新增
    addOfficial() {
      this.titleModal = "新增公众号";
      this.addChangeStatus = true;
    },
    addChangeFn(status) {
      if(!status) {Object.keys(this.addChangeData).forEach(key => {this.addChangeData[key] = ''})};
      this.addChangeStatus = status;
    },
    //修改
    changeOfficial(row) {
      this.changeId = row.id;
      this.titleModal = "修改公众号";
      this.addChangeData = JSON.parse(JSON.stringify(row));
      this.addChangeStatus = true;
    },
   addChangeSave() {
     this.$refs.addOfficial.addChangeSave();
   },
    //表格多选
    tableSelect(arr) {
      let a = [];
      arr.map((item) => {
        a.push(item.id);
      });
      this.tableSelectStr = a.join(",");
    },
    //删除
    deleteRow(id) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: '<p>您正在删除公众号信息，是否继续？</p>',
         onOk: () => {
            this.$get("/voteapi/api/pc/aggre/application/deleteAggreApplication", {
        ids: id,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "删除成功",
            });
            this.hadlePageSize({
              page: this.curPage,
              pageSize: this.pageSize
            })
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {});
        },
      })
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/voteapi/api/pc/aggre/application/selectAggreApplication", {
        page: obj.page,
        pageSize: obj.pageSize,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        type: this.searchForm.type,
        companyUnit: this.searchForm.companyUnit,
        modelName: this.searchForm.modelName,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.tableData = res.dataList;
            this.total = res.maxPage;
            this.pageSize = res.pageSize;
            this.curPage = res.currentPage;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
          this.loading = false;
        });
    },
  },
  created() {
    this.menuId = this.$core.getUrlParam('menuId');
    this.hadlePageSize({
      page: this.curPage,
      pageSize: this.pageSize,
    });
    //获取查询类型
     this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "AGGRE_APPLICATION_TYPE"
      }).then(res => {
        if(res.code == 200 && res.dataList) {
          this.selectTypeList=res.dataList;
        }else {
          this.$Message.error({
            background: true,
            content: '获取类型失败'
          })
        }
      })
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddOChangefficial
  },
};
</script>
    
<style scoped lang='less'>
</style>